import React, { useContext, useEffect, useState } from 'react'
import Aside from '../../../Layout/Aside'
import Navbar from '../../../Layout/Navbar'
import Footer from '../../../Layout/Footer'
import { MainContext } from '../../../Store/Context'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Icons } from '../../../Icons/IconIndex'
import Axios from 'axios'
import { apiURL } from '../../../Layout/Common/confiqurl'
import Select from 'react-select';
import { Toaster, toast } from 'react-hot-toast'
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import ToolkitProvider from 'react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit';
import { Button, Modal, Form } from "react-bootstrap";
import "./style.css";


const AssignPartner = () => {
  const { user } = useContext(MainContext)
  const [state, setState] = useState([])
  const [selectedValues, setSelectedValues] = useState([]);
  const [selectedOption, setSelectedOption] = useState({ id: '', name: '' });
  const [data, setData] = useState([]);
  const [asvalue, setAsValue] = useState();
  const [adminId, setAdminId] = useState('');
  const [adminName, setAdminName] = useState('');
  const [partnerName, setPartnerName] = useState([]);
  const [isToastOpen, setIsToastOpen] = useState(false);
  const [adminname, setadminname] = useState('');
  const [adminID, setadminID] = useState('');
  const [partnerId, setPartnerId] = useState('');
  const [partnername, setpartnername] = useState('');
  const [adminList, setAdminList] = useState([]);
  const [viewData, setViewData] = useState({});
  const [openEditPopup, setOpenEditPopup] = useState(false);
  const [updatedAdminData, setUpdatedAdminData] = useState({
    admin_id: '', 
    admin_name: '', 
    id: null,
  });
  const [sortConfig, setSortConfig] = useState(
    { key: 'id',  direction: 'descending' },
    { key: 'admin_name',  direction: 'descending' },
    { key: 'partner_name',  direction: 'descending' },
    { key: 'created_at',  direction: 'descending' },
    { key: 'created_by',  direction: 'descending' },
  )
  const [viewValue, setviewValue] = useState()
  const [openpopupView, setopenpopupView] = useState(false);
  const openViewPopup = (rowData) => {
    setViewData(rowData);
    setopenpopupView(true);
  };
  const handleView = (id) => {
    const project = JSON.parse(sessionStorage.getItem('data'));
    Axios.get(`${apiURL}/get_admin_mapping_with_partner?id=${id}`,
      {
        headers: {
          'Authorization': `Bearer ${project.access_token}`,
          "accept": "application/json",
          "Content-Type": " application/json",
        }
      },)
      .then((response) => {

        setadminname(response.data.admin_name)
        setadminID(response.data.admin_id)
        setpartnername(response.data.partner_name)
        setPartnerId(response.data.partner_id)
      });
  }
 
  const [openpopupDelete, setopenpopupDelete] = useState(false);
  const [deleteValue, setDeleteValue] = useState()
  const closeModalDelete = () => {
    setopenpopupDelete(false);
  };

  const openModalDelete = (id) => {
    setopenpopupDelete(true);
    setDeleteValue(id)
  }
  const handleDelete = (id) => {
    const project = JSON.parse(sessionStorage.getItem('data'));
    Axios.delete(`${apiURL}/delete_admin_mapping_with_partner?id=${id}`,
      {
        headers: {
          'Authorization': `Bearer ${project.access_token}`,
          "accept": "application/json",
          "Content-Type": " application/json",
        }
      },)
      .then((res) => {
        setState(state.filter((item) => item.id !== id));
        getAssignPartnerDetails();
        closeModalDelete()
        toast.success("Data Deleted Successfully");
      });
  }

  const [value, setValue] = useState([]);
  const [value1, setValue1] = useState([]);
  const [value2, setValue2] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(5);
  const [searchQuery, setSearchQuery] = useState('');
  const filteredData = state.filter((item) => {
    const itemValues = Object.values(item).join('').toLowerCase();
    return itemValues.includes(searchQuery.toLowerCase());
  });
  const arrayObject = (value) => {
   
    value.map((value) => (
      setValue1([...value1, value.label]),
      setValue2([...value2, value.value])

    ))
  }
  const sortedData = filteredData.sort((a, b) => {
    if (a[sortConfig.key] < b[sortConfig.key]) {
      return sortConfig.direction === 'ascending' ? -1 : 1;
    }
    if (a[sortConfig.key] > b[sortConfig.key]) {
      return sortConfig.direction === 'ascending' ? 1 : -1;
    }
    return 0;
  });

  const handleSort = (key) => {
    let direction = 'ascending';
    if (sortConfig.key === key && sortConfig.direction === 'ascending') {
      direction = 'descending';
    }
    setSortConfig({ key, direction });
  };
  const handleSelectionChange = (selectedItems) => {
    setSelectedValues(selectedItems);
    arrayObject(selectedItems);
  }
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentPages = sortedData.slice(indexOfFirstItem, indexOfLastItem);
  const pageNumbers = [];
  for (let i = 1; i <= Math.ceil(filteredData.length / itemsPerPage); i++) {
    pageNumbers.push(i);
  }
  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };
  const handleItemsPerPageChange = (e) => {
    setItemsPerPage(Number(e.target.value));
    setCurrentPage(1); 
  };
  useEffect(() => {
    setAsValue(JSON.parse(sessionStorage.getItem('data')))
    getData()
    getAssignPartnerDetails()
    fetchAdminNames();
    const tokenData = JSON.parse(sessionStorage.getItem('data'));
    if (tokenData) {
      setAdminId(tokenData.user_id);
      setAdminName(tokenData.user_name);
    }
    
    PartnerData();
    window.scrollTo(0, 0);
  }, []);
  const PartnerData = () => {
    const partnervalue = [{}]
    const project = JSON.parse(sessionStorage.getItem('data'));
    Axios.get(`${apiURL}/All_partner_user_details1`,
      {
        headers: {
          'Authorization': `Bearer ${project.access_token}`,
          "accept": "application/json",
          "Content-Type": " application/json",
        }
      },)
      .then((response) => {
        for (let i = 0; i < response.data.length; i++) {
          const name = response.data[i].user_name
          const id = response.data[i].user_id
          partnervalue.push({ label: name, value: id })
        }
        const cleanedPartnervalue = partnervalue.filter(partner => partner.label);
        const sortedpartnervalue = cleanedPartnervalue.sort((a, b) => a.label.localeCompare(b.label));
        setPartnerName(sortedpartnervalue)

      })
  }

  const getData = () => {
    const project = JSON.parse(sessionStorage.getItem('data'));
    Axios.get(`${apiURL}/All_admin_user_details`,
      {
        headers: {
          'Authorization': `Bearer ${project.access_token}`,
          "accept": "application/json",
          "Content-Type": " application/json",
        }
      },)
      .then((response) => {
        setData(response.data)
      })
  }

  const getAssignPartnerDetails = () => {
    const project = JSON.parse(sessionStorage.getItem('data'));
    Axios.get(`${apiURL}/Get_partner_name?admin_id=${project.user_id}`,
      {
        headers: {
          'Authorization': `Bearer ${project.access_token}`,
          "accept": "application/json",
          "Content-Type": " application/json",
        }
      },)
      .then((response) => {
        const formattedData = response.data.map(item => ({
          ...item,
          created_at: new Date(item.created_at).toLocaleString('en-US', {
            year: 'numeric',
            month: 'numeric',
            day: 'numeric',
            hour: '2-digit',
            minute: '2-digit',
            second: '2-digit',
            hour12: false,
            timeZone: 'UTC' 
          })
        }));
        setState(formattedData);
 
      })
  }
  const handleChange = (e) => {
    const selectedName = e.target.options[e.target.selectedIndex].text;
    const selectedId = e.target.value; 
    setSelectedOption({ id: selectedId, name: selectedName });
  }

  const handleAssignPartner = (e) => {
    debugger;
    const token = JSON.parse(sessionStorage.getItem('data'));
    if(value1.length > 0 && value2.length > 0){
      setIsToastOpen(true); 
      if (token) {
        const adminId = token.user_id;
        const adminName = token.user_name;
        const payload = {
          partner_names: value1,
          partner_ids: value2,
        };
        Axios.post(`${apiURL}/partner_and_admin?admin_id=${adminId}&admin_name=${adminName}`, payload,
          {
            headers: {
              'Authorization': `Bearer ${token.access_token}`,
              "accept": "application/json",
              "Content-Type": " application/json",
            }
          },
        ).then((response) => {
   
          getAssignPartnerDetails()
          setSelectedValues([]);
          setValue1([]);
          setValue2([]);
          PartnerData() 
          toast.success('Partner Assigned Successfully')
        })
          .catch((err) => {
            {
              toast.error(err.response.data.detail)
            }
          });
        setTimeout(() => {
          setIsToastOpen(false);
        }, 3000); 
      }
    }else{
      toast.error("Please Select Partner")
    }
    
  }
    ;

  const MySearch = (props) => {
    let input;
    const handleClick = () => {
      props.onSearch(input.value);
    };
    return (
      <input
        className="form-control inputs w-75 me-5"
        ref={n => input = n}
        type="text"
        placeholder="search"
        onChange={handleClick}
      />
    );
  };
  const Sessiondata = JSON.parse(sessionStorage.getItem('data'));

  const sizePerPageOptionRenderer = ({
    text,
    page,
    onSizePerPageChange
  }) => (
    <li
      key={text}
      role="presentation"
      className="dropdown-item "

    >
      <a
        tabIndex="-1"
        role="menuitem"
        data-page={page}
        onMouseDown={(e) => {
          e.preventDefault();
          onSizePerPageChange(page);
        }}

      >
        {text}
      </a>
    </li>
  );



  const customTotal = (from, to, size) => (
    <span className="react-bootstrap-table-pagination-total ms-3">
      Showing {from} to {to} of {size} Results
    </span>
  );

  const options = {
    paginationSize: 4,
    pageStartIndex: 0,

    firstPageText: 'First',
    prePageText: 'Back',
    nextPageText: 'Next',
    lastPageText: 'Last',
    nextPageTitle: 'First page',
    prePageTitle: 'Pre page',
    firstPageTitle: 'Next page',
    lastPageTitle: 'Last page', 
    paginationTotalRenderer: customTotal,
    disablePageTitle: true,
    sizePerPageOptionRenderer, 
  };
 
  const closeModalView = (id) => {
    setviewValue(id) 
    setopenpopupView(false);
  };
  const openModalView = (id) => {
    setopenpopupView(true);
 
    setviewValue(id) 
  }  
  const handleCloseEditPopup = () => {
    setOpenEditPopup(false); 
    handleView();
  };
  const handleRowClick = (row) => {
 
    const extractedPartnerId = row.partner_id;  
    setPartnerId(extractedPartnerId);  
 
  };
 
  const handleEditClick = (adminData) => {
    setOpenEditPopup(true);
    setUpdatedAdminData({
      partner_name: adminData.partner_name,
      admin_name: adminData.admin_name,
    });
  };

  const handleChange1 = (event) => {
    const { name, value } = event.target;
    setUpdatedAdminData(prev => ({
      ...prev,
      [name]: value
    }));
  }; 
  const handleUpdate = () => {
    const project = JSON.parse(sessionStorage.getItem('data'));
    Axios.put(`${apiURL}/update_admin_mapping_with_partner?id=${partnerId}`, updatedAdminData, {
      headers: {
        'Authorization': `Bearer ${project.access_token}`,
        "accept": "application/json",
        "Content-Type": " application/json",
      }
    })
      .then((response) => { 
        setState(prevState => prevState.map(item => {
          if (item.id === updatedAdminData.id) {
            return response.data; 
          }
          return item; 
        }));
        handleCloseEditPopup();
      })
      .catch((error) => {
        console.error('Error updating data:', error);
      });
  }; 
  const fetchAdminNames = () => {
    const project = JSON.parse(sessionStorage.getItem('data'));
    Axios.get(`${apiURL}/All_admin_user_details`,
      {
        headers: {
          'Authorization': `Bearer ${project.access_token}`,
          "accept": "application/json",
          "Content-Type": " application/json",
        }
      },)
      .then((response) => {
  
        if (response.data && Array.isArray(response.data)) { 
 
          setAdminList(response.data);
        } 

      });
  }

  return (
    <div className={user ? "g-sidenav-show bg-gray-100 g-sidenav-hidden " : "g-sidenav-show  bg-gray-100"} >
      <Aside />
      <Toaster toastOptions={{ position: "top-right" }} />
      <main className="main-content position-relative max-height-vh-100 h-100 border-radius-lg ">
        <Navbar Name='Assign Partners' />
        <div className="container-fluid py-4" style={{ minHeight: "560px" }}>
          <div className='card p-4 mt-4'>
            <div className='row '>
              <div className='col-4' >
                <label>Admin</label>
                <input type="text" class="form-select " value={asvalue && asvalue.user_name} disabled />
              </div>
              <div className='col-4' >
                <label>Select Partners</label>
                <div className="form-group ">
                  <Select
                    isMulti
                    options={partnerName}
                    value={selectedValues}
                    onChange={handleSelectionChange}
                  />                   </div>
              </div>
              <div className='col-4 d-flex justify-content-start mt-4 pt-2' >
                <div className=' '>
                  <button type="button" class="btn btn-outline-primary mx-2 btn-sm  ms-3" data-bs-toggle="modal" data-bs-target="#import" style={{
                    backgroundColor: 'white',
                    color: '#cb0c9f',
                    border: '1px solid #cb0c9f',
                  }} >cancel</button>
                </div>
                <div className=' '>
                  <button type="button" class="btn bg-gradient-primary btn-sm mb-0 ms-3  " target="_blank" onClick={() => { handleAssignPartner() }}  > Save</button>
                </div>
              </div>
            </div>
          </div>
          <div class="row mt-4">
            <div class="col-12">
              <div class="card">
                <div class="card-header mt-2 d-flex justify-content-between ">
                  <div>
                    <h5 class="mb-0">All Mapping Details</h5>
                    <p class="text-sm mb-0">
                    </p>
                  </div>
                </div>
                <div class="table-responsive">
                  <div class="dataTable-wrapper dataTable-loading no-footer sortable searchable fixed-height fixed-columns">
                    <div class="dataTable-top">
                      <div class="dataTable-dropdown">
                        <label style={{ width: "300px" }}>
                          <select class="dataTable-selector w-50 me-2"  
                          onChange={handleItemsPerPageChange}
                          >
                            <option value="5" >5</option>
                            <option value="10" >10</option>
                            <option value="15">15</option>
                            <option value="20">20</option>
                            <option value="25">25</option>
                          </select>
                          entries per page</label>
                      </div>
                      <div class="dataTable-search">
                        <input class="dataTable-input" placeholder="Search..." type="text" value={searchQuery} onChange={(event) => setSearchQuery(event.target.value)} />
                      </div>
                    </div>
                    <div class="dataTable-container" >
                      <table class="table table-flush dataTable-table" id="datatable-search">
                        <thead class="thead-light">
                          <tr><th data-sortable="" style={{ width: "10.8797%" ,cursor: "pointer"}} >
                            <a  class=""><label>S.No </label></a>
                          </th>
                            <th data-sortable="" style={{ width: "10.8797%",cursor: "pointer" }} onClick={() => handleSort('admin_name')}>
                              <a  class=""><label>ADMIN {sortConfig.key === 'admin_name' && (sortConfig.direction === 'ascending' ? '▲' : '▼')}</label></a>
                            </th>
                            <th data-sortable="" style={{ width: "10.8797%" ,cursor: "pointer"}} onClick={() => handleSort('partner_name')}>
                              <a  class=""><label>PARTNERS {sortConfig.key === 'partner_name' && (sortConfig.direction === 'ascending' ? '▲' : '▼')}</label></a>
                            </th>
                            <th data-sortable="" style={{ width: "10.8797%",cursor: "pointer" }} onClick={() => handleSort('created_at')}>
                              <a  class=""><label>UPDATED AT {sortConfig.key === 'created_at' && (sortConfig.direction === 'ascending' ? '▲' : '▼')}</label></a>
                            </th>

                            <th data-sortable="" style={{ width: "14.7845%",cursor: "pointer" }} onClick={() => handleSort('created_by')}>
                              <a  class=""><label>UPDATED BY {sortConfig.key === 'created_by' && (sortConfig.direction === 'ascending' ? '▲' : '▼')}</label></a>
                            </th>
                            <th data-sortable="" style={{ width: "13.0304%",cursor: "pointer" }}>
                              <label>Actions</label>
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          {

                            currentPages ? currentPages.map((item, i) =>

                              <tr key={i + 1}>

                                <td class="text-sm "><div className='mt-2 ms-3 '>  {i+ 1}</div></td>

                                <td class="text-sm">{item.admin_name}</td>
                                <td class="text-sm">{item.partner_name}</td>

                                <td class="text-sm">{item.created_at}</td>
                                <td class="text-sm px-4">{Sessiondata.user_name}</td>

                                <td class="text-sm">
                                  <a data-bs-toggle="tooltip" data-bs-original-title="Preview product" onClick={() => { handleView(item.id); openModalView(item.id); }}>
                                    <i className="text-secondary" aria-hidden="true" >
                                      <FontAwesomeIcon icon={Icons.faEye} />
                                    </i>
                                  </a>
                                  <a data-bs-toggle="tooltip" className='mx-3' data-bs-original-title="Edit Hall" >
                                    <i class="text-secondary" aria-hidden="true">

                                    </i>
                                  </a>

                                  <a data-bs-toggle="tooltip" data-bs-original-title="Delete Hall" onClick={() => openModalDelete(item.id)} >
                                    <i className="text-secondary" aria-hidden="true">
                                      <FontAwesomeIcon icon={Icons.faTrash} />
                                    </i>
                                  </a>
                                </td>
                              </tr>
                            ) : (<p>No</p>)}
                        </tbody>
                      </table>
                    </div>
                    <div class="dataTable-bottom mt-5">
                      <div class="dataTable-info">Showing {indexOfFirstItem + 1} to  {currentPage === pageNumbers.length ? filteredData.length : indexOfLastItem}   of {filteredData.length} entries</div>
                      <nav class="dataTable-pagination">
                        <ul class="dataTable-pagination-list">
                          <li class="pager"> 
                            <a
                              onClick={() => handlePageChange(currentPage - 1)}
                              className={currentPage === 1 ? "button-disable" : ""}
                              style={{ cursor: "pointer" }} > ‹ </a>
                          </li>
                          {pageNumbers.map((number) => (
                            <li key={number} className={number == currentPage ? "active" : " pager"}>
                              <a
                                onClick={() => setCurrentPage(number)}
                                
                                className={""}
                                style={{ cursor: "pointer" }} >
                                {number}
                              </a>
                            </li>
                          ))}
                           <li class="pager"><a data-page="2"
                            onClick={() => handlePageChange(currentPage + 1)}
                            className={currentPage === pageNumbers.length ? "button-disable" : ""}
                            style={{ cursor: "pointer" }}>›</a></li>
                        </ul></nav></div></div>
                </div>
              </div>
            </div>
          </div> 

          <Modal show={openpopupView} size='lg' className='model-popup-design' >
            <div className='d-flex justify-content-between mt-3' >


              <Modal.Title className='ms-4 '>View Details</Modal.Title>
              <button className='me-3 btn btn-outline-secondary  ' onClick={closeModalView}>
                x
              </button>
            </div>
            <div className='ms-4'>

            </div>
            <div className='loginmodalbody' style={{ width: 'auto' }}>
              <Modal.Body className='testing-test'>
                <Form.Group >
                  <Form.Label>Admin Name:</Form.Label>
                  <Form.Control
                    type="text"
                    name="name"
                    onChange={handleChange}
                    value={adminname}
                    placeholder="Adminame"
                    disabled='true'
                  />
                </Form.Group>
                <Form.Group >
                  <Form.Label>AdminID:</Form.Label>
                  <Form.Control
                    type="text"
                    name="name"
                    onChange={handleChange}
                    value={adminID}
                    placeholder="adminID"
                    disabled='true'
                  />
                </Form.Group>
                <Form.Group >
                  <Form.Label>Partner Name:</Form.Label>
                  <Form.Control
                    type="text"
                    name="name"
                    onChange={handleChange}

                    value={partnername}

                    placeholder="Partnername"
                    disabled='true'
                  />
                </Form.Group>
                <Form.Group >
                  <Form.Label>Partner ID:</Form.Label>
                  <Form.Control
                    type="text"
                    name="name"
                    onChange={handleChange}

                    value={partnerId}

                    placeholder="partnerID"
                    disabled='true'
                  />
                </Form.Group>


              </Modal.Body>
            </div>
            <Modal.Footer>
              <Button variant="secondary" style={{
                backgroundColor: 'white',
                color: '#cb0c9f',
                border: '1px solid #cb0c9f',
              }} onClick={closeModalView}>
                Cancel
              </Button>


            </Modal.Footer>

          </Modal>
 
          <Modal show={openpopupDelete} size='md' className='model-popup-design' >
            <div className='d-flex justify-content-between mt-3' >

              <Modal.Title className='ms-4 '>Delete</Modal.Title>
              <button className='me-3 btn btn-outline-secondary  ' onClick={closeModalDelete}>
                x
              </button>
            </div>
            <div className='loginmodalbody' style={{ width: '785px' }}>
              <Modal.Body className='testing-test' >

                Are you sure you want to delete this item ?
              </Modal.Body>
            </div>
            <Modal.Footer>
              <Button variant="danger" style={{
                backgroundImage: 'linear-gradient(310deg, #7928ca, #ff0080)',
                color: 'white',
                border: 'none',
              }} onClick={() => handleDelete(deleteValue)}>
                Delete
              </Button>
              <Button variant="secondary" style={{
                backgroundColor: 'white',
                color: '#cb0c9f',
                border: '1px solid #cb0c9f',
              }} onClick={closeModalDelete}>
                Cancel
              </Button>


            </Modal.Footer>
          </Modal>
 
          <Modal show={openEditPopup} onHide={() => setOpenEditPopup(false)}>
            <Modal.Header closeButton>
              <Modal.Title>Edit Details</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              
              <Form.Group controlId="adminName">
                <Form.Label>Admin Name</Form.Label>
                <Form.Control
                  as="select"
                  name="admin_name"
                  value={updatedAdminData.admin_name}
                  onChange={handleChange1}
                >
                  <option value="">Select Admin</option>
                  {adminList && adminList.map((admin) => (
                    <option key={admin.id} value={admin.name}>
                      {admin.name}
                    </option>
                  ))}
                </Form.Control>
              </Form.Group>

            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={handleCloseEditPopup}>
                Close
              </Button>
              <Button variant="primary" onClick={handleUpdate}>
                Update Changes
              </Button>
            </Modal.Footer>
          </Modal>
        </div>
        <Footer />
      </main>

    </div>
  )
}

export default AssignPartner