import React, { useContext, useEffect, useState } from 'react';
import Aside from '../../Layout/Aside';
import Navbar from '../../Layout/Navbar';
import Footer from '../../Layout/Footer';
import { MainContext } from '../../Store/Context';
import Axios from 'axios';
import { apiURL } from '../../Layout/Common/confiqurl';
import { Toaster, toast } from 'react-hot-toast';
import "./style.css";
import { faToggleOff, faToggleOn } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Icons } from '../../Icons/IconIndex';
import { Button, Modal, Form } from "react-bootstrap";

const Admintable = () => {
    const { user } = useContext(MainContext);
    const [isToastOpen, setIsToastOpen] = useState(false);
    const [state, setState] = useState([]);
    const [originalData, setOriginalData] = useState([]);
    const [searchQuery, setSearchQuery] = useState('');
    const [currentPage, setCurrentPage] = useState(1);
    const [cancelled_enquiry, setcancelled_enquiry] = useState([]);
    const [cancelled_bookings, setcancelled_bookings] = useState([]);
    const [message, setMessage] = useState('');
    const [mode, setMode] = useState('default');
    const [openpopupView1, setopenpopupView1] = useState(false);
    const [editeValue, setEditValue] = useState();
    const [viewValue, setviewValue] = useState();
    const [editedData, setEditedData] = useState({});
    const [bookings, setBookings] = useState([]);
    const [selectedBooking, setSelectedBooking] = useState({});
    const [sortConfig, setSortConfig] = useState({});
    const [itemsPerPage, setItemsPerPage] = useState(5);
    const [data, setData] = useState();
    const [datas, setDatas] = useState();
    const [openpopupDelete, setopenpopupDelete] = useState(false);
    const [deleteValue, setDeleteValue] = useState();

    useEffect(() => {
        getData();
        handleCancelBooking();
    }, []);

    const getData = () => {
        const project = JSON.parse(sessionStorage.getItem('data'));
        Axios.get(`${apiURL}/All_Booking_event`, {
            headers: {
                'Authorization': `Bearer ${project.access_token}`,
                "accept": "application/json",
                "Content-Type": " application/json",
            }
        }).then((response) => {
            const { booking, enquiry } = response.data;
            const typedBookings = booking.map(item => ({ ...item, type: 'booking' }));
            const typedEnquiries = enquiry.map(item => ({ ...item, type: 'enquiry' }));
            const combinedData = [...typedBookings, ...typedEnquiries];
            setState(combinedData);
            setOriginalData(combinedData);
        })
            .catch((err) => {
                console.log(err);
            });
    }

    const handleEdit = (value, id) => {
        const project = JSON.parse(sessionStorage.getItem('data'));
        const payload = {
            active_status: value,
        }
        if (!isToastOpen) {
            setIsToastOpen(true);
            Axios.put(`${apiURL}/cancelled_status_in_booking_enquiry?flag=booking&id=${id}`, payload, {
                headers: {
                    'Authorization': `Bearer ${project.access_token}`,
                    "accept": "application/json",
                    "Content-Type": " application/json"
                }
            }).then((response) => {
                toast.success('Successfully Updated');
                getData();
            }).catch((err) => {
                toast.error("Failed");
            });
            setTimeout(() => {
                setIsToastOpen(false);
            }, 3000);
        }
    }

    const handleToggleStatus = (id, itemMode) => {
        debugger;
        if (itemMode === 'enquiry') {
            toast.error("Cannot Edit Toggle Status For Enquiries.");
            return;
        }
        const updatedData = state.map((row) => {
            if (row.id === id) {
                setData(!row.active_status);
                handleEdit(!row.active_status, id);
                return { ...row, active_status: !row.active_status };
            }
            return row;
        });
        setState(updatedData);
    };

    const handleSort = (key) => {
        let direction = 'ascending';
        if (sortConfig.key === key && sortConfig.direction === 'ascending') {
            direction = 'descending';
        }
        setSortConfig({ key, direction });

        const sortedData = [...state].sort((a, b) => {
            if (a[key] < b[key]) {
                return direction === 'ascending' ? -1 : 1;
            }
            if (a[key] > b[key]) {
                return direction === 'ascending' ? 1 : -1;
            }
            return 0;
        });
        setState(sortedData);
    };

    const filteredData = Array.isArray(state)
        ? state.filter((item) => {
            const itemValues = Object.values(item).join('').toLowerCase();
            return itemValues.includes(searchQuery.toLowerCase());
        })
        : [];

    const handleClick = (page) => {
        setCurrentPage(page);
    };

    const handleItemsPerPageChange = (e) => {
        setItemsPerPage(Number(e.target.value));
        setCurrentPage(1);
    };

    const pages = Math.ceil(filteredData.length / itemsPerPage);
    const startPage = Math.max(1, currentPage - 2);
    const endPage = Math.min(pages, startPage + 4);
    const startIndex = (currentPage - 1) * itemsPerPage;
    const endIndex = Math.min(startIndex + itemsPerPage - 1, filteredData.length - 1);
    const displayedItems = filteredData.slice(startIndex, endIndex + 1);
    const paginationButtons = [];
    for (let i = startPage; i <= endPage; i++) {
        paginationButtons.push(
            <li key={i} className={i === currentPage ? "active" : ""}>
                <a key={i} className='active' onClick={() => handleClick(i)} style={{ cursor: "pointer" }}>
                    {i}
                </a>
            </li>
        );
    }

    const handleCancelBooking = () => {
        const project = JSON.parse(sessionStorage.getItem('data'));
        Axios.put(`${apiURL}/cancelled_status_in_booking_enquiry`, {
            flag: 'enquiry',
            id: project.user_id,
            active_status: true,
        }, {
            headers: {
                'Authorization': `Bearer ${project.access_token}`,
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            }
        })
            .then(response => {
            })
    };

    const closeModalView1 = (id) => {
        setopenpopupView1(false);
    };
    const handleView = (id) => {
        const token = JSON.parse(sessionStorage.getItem('data'));
        const payloadData = {
            ...selectedBooking,
        };
        const project = JSON.parse(sessionStorage.getItem('data'));
        const queryString = Object.keys(selectedBooking).map(key => `${encodeURIComponent(key)}=${encodeURIComponent(selectedBooking[key])}`).join('&');
        Axios.put(`${apiURL}/Booking_Event_Update/${id}?${queryString}`, null, {
            headers: {
                'Authorization': `Bearer ${project.access_token}`,
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
        })
            .then((res) => {
                console.log("edit api response", res.data)
                if (res.status === 200) {
                    setSelectedBooking(res.data);
                    setopenpopupView1(true);
                    getData(res.data)
                }
            })
            .catch((error) => {
                console.error("Error fetching booking details:", error);
            });
    };
    const handleView1 = (id) => {
        const token = JSON.parse(sessionStorage.getItem('data'));
        const payloadData = {
            ...selectedBooking,
        };
        const project = JSON.parse(sessionStorage.getItem('data'));
        const queryString = Object.keys(selectedBooking).map(key => `${encodeURIComponent(key)}=${encodeURIComponent(selectedBooking[key])}`).join('&');
        Axios.put(`${apiURL}/Booking_Event_Update/${id}?${queryString}`, null, {
            headers: {
                'Authorization': `Bearer ${project.access_token}`,
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
        })
            .then((res) => {
                if (res.status === 200) {
                    toast.success("Updated Data Succesfully");
                    setSelectedBooking(res.data);
                    getData(res.data)
                    closeModalView1(id);
                }
            })
            .catch((error) => {
                console.error("Error fetching booking details:", error);
            });
    };
    const openModalView1 = (id) => {
        handleView(id);
        setEditedData({ ...data });
        setopenpopupView1(true);
        setviewValue(id);
        setEditValue(id);
    };

    const closeModalDelete = () => {
        setopenpopupDelete(false);
    };

    const openModalDelete = (id) => {
        setopenpopupDelete(true);
        setDeleteValue(id);
    };

    const getDatadelete = () => {
        const id = deleteValue;
        const project = JSON.parse(sessionStorage.getItem('data'));
        Axios.delete(`${apiURL}/Booking_Event_Delete?id=${id}`, {
            headers: {
                'Authorization': `Bearer ${project.access_token}`,
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            }
        })
            .then(response => {
                if (response.data.detail === "Booking Event Deleted Successfully") {
                    toast.success("Booking Event Deleted Successfully");
                    getData();
                    setopenpopupDelete(false);
                }
            })
            .catch(error => {
                toast.error("Error deleting booking detail.");
            });

    };

    return (
        <div className="py-4" style={{ minHeight: "560px" }}>
            <div className="row mt-4">
                <div className="col-12" style={{ padding: "0px" }}>
                    <div className="card">
                        <div className="card-header">
                            <h5 className="mb-0">All Booking</h5>
                            <p className="text-sm mb-0"></p>
                        </div>
                        <div className="table-responsive">
                            <div className="dataTable-wrapper dataTable-loading no-footer sortable searchable fixed-height fixed-columns">
                                <div className="dataTable-top">
                                    <div className="dataTable-dropdown">
                                        <label style={{ width: "300px" }}>
                                            <select className="dataTable-selector w-50 me-2" style={{ cursor: "pointer" }} onChange={handleItemsPerPageChange}>
                                                <option value="5">5</option>
                                                <option value="10">10</option>
                                                <option value="15">15</option>
                                                <option value="20">20</option>
                                                <option value="25">25</option>
                                            </select>
                                        </label>
                                    </div>
                                    <div className="dataTable-search">
                                        <input className="dataTable-input w-100" placeholder="Search..." type="text" value={searchQuery} onChange={(e) => setSearchQuery(e.target.value)} />
                                    </div>
                                </div>
                                <div className="dataTable-container" style={{ maxHeight: "360px", overflow: "auto" }}>
                                    <table className="table table-flush dataTable-table">
                                        <thead className="thead-light">
                                            <tr>
                                                <th style={{ fontSize: "15px", color: "#344767" }}>S.No </th>
                                                <th onClick={() => handleSort('place_name')} style={{ fontSize: "15px", color: "#344767", cursor: "pointer" }}>Place Name</th>
                                                <th onClick={() => handleSort('event_type')} style={{ fontSize: "15px", color: "#344767", cursor: "pointer" }}>Event Type</th>
                                                <th onClick={() => handleSort('start_date')} style={{ fontSize: "15px", color: "#344767", cursor: "pointer" }}>Start Date</th>
                                                <th onClick={() => handleSort('end_date')} style={{ fontSize: "15px", color: "#344767", cursor: "pointer" }}>End Date</th>
                                                <th onClick={() => handleSort('start_time')} style={{ fontSize: "15px", color: "#344767", cursor: "pointer" }}>Start Time</th>
                                                <th onClick={() => handleSort('end_time')} style={{ fontSize: "15px", color: "#344767", cursor: "pointer" }}>End Time</th>
                                                <th onClick={() => handleSort('contact_number')} style={{ fontSize: "15px", color: "#344767", cursor: "pointer" }}>Contact number</th>
                                                <th onClick={() => handleSort('email_id')} style={{ fontSize: "15px", color: "#344767", cursor: "pointer" }}>EmailID</th>
                                                <th onClick={() => handleSort('name')} style={{ fontSize: "15px", color: "#344767", cursor: "pointer" }}>Name</th>
                                                <th onClick={() => handleSort('type')} style={{ fontSize: "15px", color: "#344767", cursor: "pointer" }}>Mode</th>
                                                <th onClick={() => handleSort('active_status')} style={{ fontSize: "15px", color: "#344767", cursor: "pointer" }}>Status</th>
                                                <th style={{ fontSize: "15px", color: "#344767" }}>Action 1</th>
                                                <th style={{ fontSize: "15px", color: "#344767" }}>Action 2</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {displayedItems.length > 0 ? displayedItems.map((row, index) => (
                                                <tr>
                                                    <td class="text-sm">{index + 1}</td>
                                                    <td class="text-sm">{row.place_name}</td>
                                                    <td class="text-sm">{row.event_type}</td>
                                                    <td class="text-sm">{row.start_date}</td>
                                                    <td class="text-sm">{row.end_date}</td>
                                                    <td class="text-sm">{row.start_time}</td>
                                                    <td class="text-sm">{row.end_time}</td>
                                                    <td class="text-sm">{row.contact_number}</td>
                                                    <td class="text-sm">{row.email_id}</td>
                                                    <td class="text-sm">{row.name}</td>
                                                    <td className={`text-sm font-weight-normal ${row.type === 'booking' ? 'text-blue' : 'text-red'}`}
                                                        style={{ fontSize: "12px" }}>
                                                        {row.type === 'booking' ? <span style={{ color: 'green', fontWeight: '700', fontSize: '16px', }}>Booking</span> : <span style={{ color: 'blue', fontSize: '16px', fontWeight: '700' }}>Enquiry</span>}
                                                    </td>
                                                    <td class="text-sm">
                                                        {row.active_status ? (<span className="badge badge-success badge-sm"> Confirmed</span>) : (<span className="badge badge-danger badge-sm">Not Confirmed</span>)}
                                                    </td>
                                                    <td class="text-sm">    <div className="form-check form-switch pt-1 ms-3" >
                                                        <input className="form-check-input" type="checkbox" id={`toggle-${row.id}`} checked={row.active_status} onChange={() => handleToggleStatus(row.id)} disabled={row.type === 'enquiry'} />
                                                    </div></td>
                                                    <td className="text-sm text-sm font-weight-normal"  >
                                                        <a data-bs-toggle="tooltip" data-bs-original-title="Preview product" >
                                                        </a>
                                                        <a data-bs-toggle="tooltip" className={`mx-3 ${row.type === 'enquiry' ? 'disabled' : ''}`} data-bs-original-title="Edit Hall"
                                                            onClick={() => { if (row.type !== 'enquiry') { handleView(row.id); openModalView1(row.id); } }} >
                                                            <i className="text-secondary" aria-hidden="true">
                                                                <FontAwesomeIcon icon={Icons.faPen} />
                                                            </i>
                                                        </a>
                                                        <a data-bs-toggle="tooltip" className={` ${row.type === 'enquiry' ? 'disabled' : ''}`} data-bs-original-title="Delete Hall"  >
                                                            <i className="text-secondary" aria-hidden="true">
                                                                <FontAwesomeIcon icon={Icons.faTrash}
                                                                    onClick={() => {
                                                                        if (row.type !== 'enquiry') {
                                                                            openModalDelete(row.id)
                                                                        }
                                                                    }} />
                                                            </i>
                                                        </a>
                                                    </td>
                                                </tr>
                                            )) : (
                                                <tr>
                                                    <td colSpan="6" className="text-center">No data found</td>
                                                </tr>
                                            )}
                                        </tbody>
                                    </table>
                                </div>
                                <div className="dataTable-bottom mt-3">
                                    <div className="dataTable-info">
                                        Showing {startIndex + 1} to {endIndex + 1} of {filteredData.length} entries
                                    </div>
                                    <nav className="dataTable-pagination">
                                        <ul className="dataTable-pagination-list">
                                            {paginationButtons}
                                        </ul>
                                    </nav>
                                </div>
                            </div>
                        </div>
                        {/* <Toaster /> */}
                    </div>
                </div>
            </div>

            <Modal show={openpopupView1} size='lg' className='model-popup-design' >
                <div className='d-flex justify-content-between mt-3' >
                    <Modal.Title className='ms-4 '>Update Booking Event Details</Modal.Title>
                    <button className='me-3 btn btn-outline-secondary'
                        onClick={closeModalView1}
                    >
                        x
                    </button>
                </div>
                <div className='ms-4'>
                </div>
                <div className='loginmodalbody' style={{ width: 'auto' }}>
                    <Modal.Body className='testing-test'>


                        <Form.Group >
                            <Form.Label>
                                Name:
                            </Form.Label>
                            <Form.Control
                                type="text"
                                name="name"
                                // onChange={handleChange1}
                                onChange={(e) => setSelectedBooking({ ...selectedBooking, name: e.target.value })}
                                value={selectedBooking ? selectedBooking.name : ''}
                                placeholder="Name"
                            />
                        </Form.Group>
                        <Form.Group >
                            <Form.Label>
                                Email:
                            </Form.Label>
                            <Form.Control
                                type="email"
                                name="email_id"
                                onChange={(e) => setSelectedBooking({ ...selectedBooking, email_id: e.target.value })}
                                value={selectedBooking ? selectedBooking.email_id : ''}
                                placeholder="Email"
                            />
                        </Form.Group>
                        <Form.Group >
                            <Form.Label>
                                Contact No:
                            </Form.Label>
                            <Form.Control
                                type="text"
                                name="contact_number"
                                onChange={(e) => setSelectedBooking({ ...selectedBooking, contact_number: e.target.value })}
                                value={selectedBooking ? selectedBooking.contact_number : ''}
                                placeholder="Contact No"
                                required
                            />
                        </Form.Group>
                        <Form.Group >
                            <Form.Label>
                                Event_Type:
                            </Form.Label>
                            <Form.Control
                                type="text"
                                name="event_type"
                                onChange={(e) => setSelectedBooking({ ...selectedBooking, event_type: e.target.value })}
                                value={selectedBooking ? selectedBooking.event_type : ''}
                                placeholder="Event Type"
                            />
                        </Form.Group>
                        <Form.Group >
                            <Form.Label>
                                Start_Time:
                            </Form.Label>
                            <Form.Control
                                type="time"
                                name="start_time"
                                onChange={(e) => setSelectedBooking({ ...selectedBooking, start_time: e.target.value })}
                                value={selectedBooking ? selectedBooking.start_time : ''}
                                placeholder="Start Time"
                            />
                        </Form.Group>
                        <Form.Group >
                            <Form.Label>
                                End_Time:
                            </Form.Label>
                            <Form.Control
                                type="time"
                                name="end_time"
                                onChange={(e) => setSelectedBooking({ ...selectedBooking, end_time: e.target.value })}
                                value={selectedBooking ? selectedBooking.end_time : ''}
                                placeholder="End Time"
                            />
                        </Form.Group>
                        <Form.Group >
                            <Form.Label>
                                Start_Date:
                            </Form.Label>
                            <Form.Control
                                type="date"
                                name="start_date"
                                onChange={(e) => setSelectedBooking({ ...selectedBooking, start_date: e.target.value })}
                                value={selectedBooking ? selectedBooking.start_date : ''}
                                placeholder="Start Date"
                                onFocus={(e) => e.target.showPicker()}
                            />
                        </Form.Group>
                        <Form.Group >
                            <Form.Label>
                                End_Date:
                            </Form.Label>
                            <Form.Control
                                type="date"
                                name="end_date"
                                onChange={(e) => setSelectedBooking({ ...selectedBooking, end_date: e.target.value })}
                                value={selectedBooking ? selectedBooking.end_date : ''}
                                placeholder="End Date"
                                onFocus={(e) => e.target.showPicker()}
                            />
                        </Form.Group>
                        <Form.Group>
                            <Form.Label>Active Status:</Form.Label>
                            <Form.Control as="select" name="active_status" onChange={(e) => setSelectedBooking({ ...selectedBooking, active_status: e.target.value === 'true' })} value={selectedBooking ? selectedBooking.active_status : ''}  >
                                <option value="">Select Active Status</option>
                                <option value="true">Active</option>
                                <option value="false">Inactive</option>
                            </Form.Control>
                        </Form.Group>
                    </Modal.Body>
                </div>
                <Modal.Footer>
                    <Button variant="secondary" style={{ backgroundImage: 'linear-gradient(310deg, #7928ca, #ff0080)', color: 'white', border: 'none', }} onClick={() => handleView1(editeValue)}>  update  </Button>
                    <Button variant="secondary" style={{ backgroundColor: 'white', color: '#cb0c9f', border: '1px solid #cb0c9f', }} onClick={closeModalView1}> Cancel </Button>
                </Modal.Footer>
            </Modal>

            <Modal show={openpopupDelete} size='md' className='model-popup-design' >
                <div className='d-flex justify-content-between mt-3' >
                    <Modal.Title className='ms-4 '>Delete</Modal.Title>
                    <button className='me-3 btn btn-outline-secondary  ' onClick={closeModalDelete}>  x </button>
                </div>
                <div className='loginmodalbody' style={{ width: '785px' }}>
                    <Modal.Body className='testing-test' >  Are you sure you want to delete this item ? </Modal.Body>
                </div>
                <Modal.Footer>
                    <Button variant="danger" style={{ backgroundImage: 'linear-gradient(310deg, #7928ca, #ff0080)', color: 'white', border: 'none', }} onClick={getDatadelete}>  Delete  </Button>
                    <Button variant="secondary" style={{ backgroundColor: 'white', color: '#cb0c9f', border: '1px solid #cb0c9f', }} onClick={closeModalDelete}>  Cancel  </Button>
                </Modal.Footer>
            </Modal>
        </div>
    );
}

export default Admintable;
