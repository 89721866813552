import React, { useContext, useEffect, useState } from 'react';
import Aside from '../../Layout/Aside';
import Navbar from '../../Layout/Navbar';
import Footer from '../../Layout/Footer';
import { MainContext } from '../../Store/Context';
import Axios from 'axios';
import { apiURL } from '../../Layout/Common/confiqurl';
import { Toaster, toast } from 'react-hot-toast';
import "./style.css";
import { faToggleOff, faToggleOn } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const EnquiryAdmin = () => {
  const { user } = useContext(MainContext);
  const [isToastOpen, setIsToastOpen] = useState(false);
  const [sortConfig, setSortConfig] = useState(null); // No initial sort
  const [originalData, setOriginalData] = useState([]); // Store original API data
  const [displayedData, setDisplayedData] = useState([]); // Data displayed in the table
  const [searchQuery, setSearchQuery] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(5);

  useEffect(() => {
    getData();
    setCurrentPage(1);
    window.scrollTo(0, 0);
  }, []);

  const formatDate = (timestamp) => {
    const date = new Date(timestamp);
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    return `${year}-${month}-${day}`;
  };

  const getData = () => {
    const project = JSON.parse(sessionStorage.getItem('data'));
    Axios.get(`${apiURL}/GetallEnquiyList`, {
      headers: {
        'Authorization': `Bearer ${project.access_token}`,
        "accept": "application/json",
        "Content-Type": " application/json",
      }
    }).then((response) => {
      const formattedData = response.data.map(item => ({
        ...item,
        created_at: formatDate(item.created_at)
      }));
      setOriginalData(formattedData); // Store original data
      setDisplayedData(formattedData); // Set displayed data
    }).catch((err) => {
      console.log(err);
    });
  };

  const handleEdit = (value, id) => {
    const project = JSON.parse(sessionStorage.getItem('data'));
    const payload = { active_status: value };
    if (!isToastOpen) {
      setIsToastOpen(true);
      Axios.put(`${apiURL}/active_status?id=${id}`, payload, {
        headers: {
          'Authorization': `Bearer ${project.access_token}`,
          "accept": "application/json",
          "Content-Type": " application/json"
        }
      }).then((response) => {
        toast.success(' Successfully Updated');
      }).catch((err) => {
        toast.error("Invalid User");
      });
      setTimeout(() => {
        setIsToastOpen(false);
      }, 3000);
    }
  };

  const handleToggleStatus = (id) => {
    const updatedData = displayedData.map((row) => {
      if (row.id === id) {
        handleEdit(!row.active_status, id);
        return { ...row, active_status: !row.active_status };
      }
      getData();
      return row; 
    });
    setDisplayedData(updatedData);
    getData();
  };


  const handleSort = (key) => {
    let direction = 'ascending';
    if (sortConfig && sortConfig.key === key && sortConfig.direction === 'ascending') {
      direction = 'descending';
    }
    setSortConfig({ key, direction });

    const sortedData = [...displayedData].sort((a, b) => {
      if (a[key] < b[key]) {
        return direction === 'ascending' ? -1 : 1;
      }
      if (a[key] > b[key]) {
        return direction === 'ascending' ? 1 : -1;
      }
      return 0;
    });
    setDisplayedData(sortedData);
  };

  const filteredData = Array.isArray(originalData)
    ? originalData.filter((item) => {
      const itemValues = Object.values(item).join('').toLowerCase();
      return itemValues.includes(searchQuery.toLowerCase());
    })
    : [];

  const handleClick = (page) => {
    setCurrentPage(page);
  };

  const handleItemsPerPageChange = (e) => {
    setItemsPerPage(Number(e.target.value));
    setCurrentPage(1);
  };

  const pages = Math.ceil(filteredData.length / itemsPerPage);
  const startPage = Math.max(1, currentPage - 2);
  const endPage = Math.min(pages, startPage + 4);
  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = Math.min(startIndex + itemsPerPage - 1, filteredData.length - 1);
  const displayedItems = filteredData.slice(startIndex, endIndex + 1);

  const paginationButtons = [];
  for (let i = startPage; i <= endPage; i++) {
    paginationButtons.push(
      <li key={i} className={i === currentPage ? "active" : ""}>
        <a
          key={i}
          className='active'
          onClick={() => handleClick(i)}
          style={{ cursor: "pointer" }}
        >
          {i}
        </a>
      </li>
    );
  }

  return (
    <div className={user ? "g-sidenav-show bg-gray-100 g-sidenav-hidden " : "g-sidenav-show  bg-gray-100"}>
      <Toaster toastOptions={{ position: "top-right" }} />
      <Aside />
      <main className="main-content position-relative max-height-vh-100 h-100 border-radius-lg ">
        <Navbar Name='Enquiry' />
        <div className="container-fluid py-4" style={{ minHeight: "560px" }}>
          <div className="row mt-4">
            <div className="col-12">
              <div className="card">
                <div className="card-header">
                  <h5 className="mb-0 mt-3">Enquiry List</h5>
                  <p className="text-sm mb-0"> </p>
                </div>
                <div className="table-responsive">
                  <div className="dataTable-wrapper dataTable-loading no-footer sortable searchable fixed-height fixed-columns">
                    <div className="dataTable-top">
                      <div className="dataTable-dropdown">
                        <label style={{ width: "300px" }}>
                          <select className="dataTable-selector w-50 me-2"
                            onChange={handleItemsPerPageChange}
                            style={{ cursor: "pointer" }}>
                            <option value="5" style={{ cursor: "pointer" }}>5</option>
                            <option value="10 " style={{ cursor: "pointer" }}>10</option>
                            <option value="15" style={{ cursor: "pointer" }}>15</option>
                            <option value="20" style={{ cursor: "pointer" }}>20</option>
                            <option value="25" style={{ cursor: "pointer" }}>25</option>
                          </select>
                          entries per page
                        </label>
                      </div>
                      <div className="dataTable-search">
                        <input className="dataTable-input" placeholder="Search..." type="text" value={searchQuery}
                          onChange={(event) => {
                            setSearchQuery(event.target.value);
                            setCurrentPage(1);
                          }} />
                      </div>
                    </div>
                    <div className="dataTable-container table-responsive w-100">
                      <table className="table  " id="datatable-search">
                        <thead className="thead-light">
                          <tr>
                            <th data-sortable="">
                              <a className="" style={{ fontSize: "14px", color: "#344767" ,cursor: "pointer"}}> S.No </a>
                            </th>
                            <th data-sortable="mx-2">
                              <a className="" style={{ fontSize: "14px", color: "#344767",cursor: "pointer" }} onClick={() => handleSort('place_name')} >
                                Place Name {sortConfig && sortConfig.key === 'place_name' && (sortConfig.direction === 'ascending' ? '▲' : '▼')}
                              </a>
                            </th>
                            <th data-sortable="mx-2">
                              <a  className="" style={{ fontSize: "14px", color: "#344767",cursor: "pointer" }} onClick={() => handleSort('event_type')} >
                                Event Type {sortConfig && sortConfig.key === 'event_type' && (sortConfig.direction === 'ascending' ? '▲' : '▼')}
                              </a>
                            </th>
                            <th data-sortable="mx-2">
                              <a  className=""  style={{ fontSize: "14px", color: "#344767",cursor: "pointer" }}  onClick={() => handleSort('created_at')}  >
                              Enquired Date {sortConfig && sortConfig.key === 'created_at' && (sortConfig.direction === 'ascending' ? '▲' : '▼')}
                              </a>
                            </th> 
                            
                            <th data-sortable="mx-2">
                              <a  className=""  style={{ fontSize: "14px", color: "#344767",cursor: "pointer" }}  onClick={() => handleSort('start_date')}  >
                              Start Date {sortConfig && sortConfig.key === 'start_date' && (sortConfig.direction === 'ascending' ? '▲' : '▼')}
                              </a>
                            </th> 
                            <th data-sortable="mx-2">
                              <a  className=""  style={{ fontSize: "14px", color: "#344767",cursor: "pointer" }}  onClick={() => handleSort('end_date')}  >
                              End Date {sortConfig && sortConfig.key === 'end_date' && (sortConfig.direction === 'ascending' ? '▲' : '▼')}
                              </a>
                            </th> 
                            <th data-sortable="mx-2">
                              <a  className=""  style={{ fontSize: "14px", color: "#344767",cursor: "pointer" }}  onClick={() => handleSort('start_time')}  >
                              Start Time {sortConfig && sortConfig.key === 'start_time' && (sortConfig.direction === 'ascending' ? '▲' : '▼')}
                              </a>
                            </th> 
                            <th data-sortable="mx-2">
                              <a  className=""  style={{ fontSize: "14px", color: "#344767",cursor: "pointer" }}  onClick={() => handleSort('end_time')}  >
                              End Time {sortConfig && sortConfig.key === 'end_time' && (sortConfig.direction === 'ascending' ? '▲' : '▼')}
                              </a>
                            </th> 
                            <th data-sortable="mx-2">
                              <a  className=""  style={{ fontSize: "14px", color: "#344767",cursor: "pointer" }}  onClick={() => handleSort('contact_number')}  >
                              Contact Number {sortConfig && sortConfig.key === 'contact_number' && (sortConfig.direction === 'ascending' ? '▲' : '▼')}
                              </a>
                            </th> 
                            <th data-sortable="mx-2">
                              <a  className=""  style={{ fontSize: "14px", color: "#344767",cursor: "pointer" }}  onClick={() => handleSort('name')}  >
                              Name {sortConfig && sortConfig.key === 'name' && (sortConfig.direction === 'ascending' ? '▲' : '▼')}
                              </a>
                            </th> 
                            <th data-sortable="mx-2">
                              <a  className=""  style={{ fontSize: "14px", color: "#344767",cursor: "pointer" }}  onClick={() => handleSort('active_status')}  >
                              Status {sortConfig && sortConfig.key === 'active_status' && (sortConfig.direction === 'ascending' ? '▲' : '▼')}
                              </a>
                            </th>  
                            <th data-sortable="mx-2">
                              <a className="" style={{ fontSize: "14px", color: "#344767",cursor: "pointer" }}> Action </a>
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          {Array.isArray(displayedItems) && displayedItems.length > 0 && displayedItems.map((userData, index) => (
                            <tr key={userData.id}>
                              <td class="text-sm" >{index + 1}</td>
                              <td class="text-sm" >{userData.place_name}</td>
                              <td class="text-sm" >{userData.event_type}</td>
                              <td class="text-sm" >{userData.created_at}</td>
                              <td class="text-sm" >{userData.start_date}</td>
                              <td class="text-sm" >{userData.end_date}</td>
                              <td class="text-sm" >{userData.start_time}</td>
                              <td class="text-sm" >{userData.end_time}</td>
                              <td class="text-sm" >{userData.contact_number}</td>
                              <td class="text-sm" >{userData.name}</td>
                              
                              <td class="text-sm" >{userData.active_status ? (<span className="badge badge-success badge-sm"> Confirmed</span>) : (<span className="badge badge-danger badge-sm">Not Confirmed</span>)}</td>
                              <td class="text-sm" > <div className="form-check form-switch pt-1 ms-3"
                              >
                                <input className="form-check-input" type="checkbox" id="rememberMe" checked={userData.active_status} onClick={() => handleToggleStatus(userData.id)} />
                              </div></td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                    <div className="dataTable-bottom">
                      <div className="dataTable-info">
                        Showing {startIndex + 1} to {endIndex + 1} of {filteredData.length} entries
                      </div>
                      <div className="dataTable-pagination">
                        <ul className="dataTable-pagination-list">
                          {paginationButtons}
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </main>
    </div>
  );
};

export default EnquiryAdmin;
